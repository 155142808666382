import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  formLabelClasses,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import TriadentStepper from "components/TriadentStepper";
import { ErrorMessage } from "@hookform/error-message";
import Pagination from "components/Pagination";
import OnNextSubmit from "components/OnNextSubmit";
import { FlowCondition } from "typescript";
import { Dispatch, Action, AnyAction } from "redux";
import NextButton from "components/NextButton";
import { radiogroep_util } from "utils/radiogroep_util";
import { klachtCompProps } from "types/redux";
import { useOutletContext } from "react-router-dom";
import { praktijkinfo } from "types/praktijkinfo";
import { OnLastSubmitKlacht } from "components/OnLastSubmit";

let loading = false;
const setLoading = (bool: boolean) => {
  loading = bool;
};

interface Props<A extends Action = AnyAction> {
  formData?: {};
  dispatch?: Dispatch;
}

type FormValues = {
  spoed: string;
  wie_tandarts: string;
};

interface WieTandartsProps extends klachtCompProps {}

const WieTandarts: React.FC<WieTandartsProps> = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: props.formData,
  });

  const praktijkInfo: praktijkinfo = useOutletContext();

  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];

  const vanuit_dashboard = new URLSearchParams(window.location.search).has(
    "vanuit_dashboard"
  );
  const spoed_vanuit_dashboard = is_spoed_praktijk && vanuit_dashboard;

  const radiogroep = radiogroep_util(errors, control);

  const { t, i18n } = useTranslation();

  const nextSubmit = OnNextSubmit(props.dispatch, {}, "/onderdelen");

  const onLastSubmit = OnLastSubmitKlacht(setLoading, null, watch("klacht"));

  const onSubmit =
    is_spoed_praktijk && watch("spoed") == "ja" ? onLastSubmit : nextSubmit;

  console.log(watch("klacht"));

  const CustTextField = ({ name, label }: { name: string; label: string }) => (
    <Grid item xs={12}>
      <FormControl fullWidth component="fieldset" error={!!errors[name]}>
        <FormLabel sx={{ mb: 1 }} component="legend">
          {label}
        </FormLabel>

        <TextField
          placeholder={"Licht toe..."}
          InputProps={register(name, { required: false })}
          multiline
          minRows={1}
          maxRows={1}
        />
      </FormControl>
    </Grid>
  );

  const TraumaVragen = () => {
    return (
      <>
        {(watch("uit_afgebroken") == "afgebroken" ||
          watch("uit_afgebroken") == "deels_afgebroken") && (
          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "stip_streep",
              t("Ziet u een rode stip of streep?")
            )}
          </Grid>
        )}

        {watch("stip_streep") == "ja" && (
          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "bloeden",
              t("Komt er bloed uit de tand?")
            )}
          </Grid>
        )}
        {(watch("uit_afgebroken") == "afgebroken" ||
          watch("uit_afgebroken") == "deels_afgebroken") && (
          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "beweegt_2mm",
              t("Beweegt de tand meer dan 2mm?")
            )}
          </Grid>
        )}
      </>
    );
  };

  const SpoedTraumaVragen = () => {
    return (
      <>
        <CustTextField
          name="waarom_niet_eigen_tandarts"
          label="Wat is de reden dat u niet bij uw eigen tandarts geholpen kunt worden?"
        />
        <CustTextField
          label="Welk tijdstip vond dit ongeval plaats?"
          name="trauma_tijdstip"
        />
        <CustTextField
          label="Waar/hoe is dit gebeurd?"
          name="trauma_waar_hoe"
        />

        <Grid item xs={12}>
          {radiogroep(
            ["ja", "nee"],
            [t("ja"), t("nee")],
            "tetanus_vaccine",
            t("Heeft u een tetanus vaccin?")
          )}
        </Grid>

        {/* <Grid item xs={12}>
          {radiogroep(
            ["ja", "nee"],
            [t("ja"), t("nee")],
            "tand_los",
            t("Is uw tand los?")
          )}
        </Grid> */}

        <Grid item xs={12}>
          {radiogroep(
            ["ja", "nee"],
            [t("ja"), t("nee")],
            "bewusteloosheid",
            t("Was er een periode van bewusteloosheid?")
          )}
        </Grid>
        <Grid item xs={12}>
          {radiogroep(
            ["ja", "nee"],
            [t("ja"), t("nee")],
            "verstoring_beet",
            t("Is er een verstoring van de beet?")
          )}
        </Grid>
        <Grid item xs={12}>
          {radiogroep(
            ["ja", "nee"],
            [t("ja"), t("nee")],
            "reactie_tanden",
            t("Is er een reactie in de tanden op blootstelling van warmte/kou?")
          )}
        </Grid>

        <Grid item xs={12}>
          {radiogroep(
            ["ja", "nee"],
            [t("ja"), t("nee")],
            "kiezen_op_elkaar",
            t("Kunt u de kiezen volledig op elkaar zetten?")
          )}
        </Grid>

        <TraumaVragen />

        <Grid item xs={12}>
          {radiogroep(
            ["ja", "nee"],
            [t("ja"), t("nee")],
            "overleg_tandarts",
            t("Overleg gehad met de dienstdoende tandarts?")
          )}
        </Grid>

        {watch("overleg_tandarts") == "ja" && (
          <Grid item xs={12}>
            <FormControl fullWidth component="fieldset">
              <FormLabel sx={{ mb: 1 }} component="legend">
                {t("Welk tijdstip mag patient komen?")}
              </FormLabel>
              <TextField
                placeholder={t("") + "Optioneel"}
                InputProps={register("tijdstip_patient_komen", {
                  required: false,
                })}
                multiline
                minRows={2}
                maxRows={5}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <FormControl fullWidth component="fieldset">
            <FormLabel sx={{ mb: 1 }} component="legend">
              {t("Extra notities (optioneel):")}
            </FormLabel>
            <TextField
              placeholder={t("Licht toe") + "..."}
              InputProps={register("extra_notitie", { required: false })}
              multiline
              minRows={2}
              maxRows={5}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} mt={1}>
          <FormControl component="fieldset" error={!!errors.verder_medicatie}>
            <FormLabel required={spoed_vanuit_dashboard} component="legend">
              {t(
                "Gebruikt u verder nog medicatie? Zo ja, neem een medicatieoverzicht mee naar uw afspraak!"
              )}
            </FormLabel>
            <Controller
              rules={{ required: true }}
              control={control}
              name="verder_medicatie"
              render={({ field }) => {
                return (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="ja"
                      control={<Radio />}
                      label={t("Ja")}
                    />
                    <FormControlLabel
                      value="nee"
                      control={<Radio />}
                      label={t("Nee")}
                    />
                  </RadioGroup>
                );
              }}
            />
          </FormControl>
        </Grid>
      </>
    );
  };

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="wie_tandarts" />
      <ErrorMessage
        errors={errors}
        name={Object.keys(errors)[0]}
        render={({ message }) => (
          <p className={"error-message"}>
            {Object.keys(errors).map((e) => t(e)).length == 1
              ? `${t("Het veld")} ${
                  Object.keys(errors).map((e) => t(e))[0]
                } ${t("is verplicht")}`
              : `${t("De velden")} ${Object.keys(errors)
                  .map((e) => t(e))
                  .join(", ")
                  .replaceAll("_", " ")
                  .replace(/,(?=[^,]*$)/, " en")} ${t("zijn verplicht")}`}
          </p>
        )}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={-1} spacing={3}>
          <Grid item xs={12} mt={1}>
            <FormControl component="fieldset" error={!!errors.spoed}>
              <FormLabel component="legend">
                {t("Is er sprake van een trauma of nabloeding?")}
              </FormLabel>
              <Controller
                rules={{ required: true }}
                control={control}
                name="spoed"
                render={({ field }) => {
                  return (
                    <RadioGroup {...field} row>
                      <FormControlLabel
                        value="ja"
                        control={<Radio />}
                        label={t("Ja")}
                      />
                      <FormControlLabel
                        value="nee"
                        control={<Radio />}
                        label={t("Nee")}
                      />
                    </RadioGroup>
                  );
                }}
              />
            </FormControl>
          </Grid>

          {watch("spoed") == "ja" && (
            <Grid item xs={12}>
              {radiogroep(
                ["trauma", "nabloeding"],
                [t("trauma"), t("nabloeding")],
                "klacht",
                t("Is het een trauma of een nabloeding?")
              )}
            </Grid>
          )}
          {watch("klacht") == "trauma" && (
            <Grid item xs={12}>
              {radiogroep(
                [
                  "ligt_er_uit",
                  "afgebroken",
                  "deels_afgebroken",
                  "tand los, verplaatst en in tandvlees geschoten",
                ],
                [
                  t("ligt_er_uit"),
                  t("afgebroken"),
                  t("deels afgebroken"),
                  t("tand los, verplaatst en in tandvlees geschoten"),
                ],
                "uit_afgebroken",
                t("Ligt tand er volledig uit of is deze afgebroken?")
              )}
            </Grid>
          )}

          {watch("spoed") == "ja" && watch("klacht") == "trauma" && (
            <SpoedTraumaVragen />
          )}
          {is_spoed_praktijk && <TraumaVragen />}

          {watch("spoed") == "ja" && watch("klacht") === "nabloeding" && (
            <Grid item xs={12}>
              {radiogroep(
                ["vandaag", "gisteren", "eergisteren", "langer_geleden"],
                [
                  t("vandaag"),
                  t("gisteren"),
                  t("eergisteren"),
                  t("langer_geleden"),
                ],
                "wanneer_extractie",
                t("Hoe lang geleden heeft de extractie plaatsgevonden?")
              )}
            </Grid>
          )}
          {watch("spoed") == "ja" && watch("klacht") == "nabloeding" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("ja"), t("nee")],
                "gaas_gebeten",
                t("Heeft u al op een gaastje gebeten?")
              )}
            </Grid>
          )}

          {watch("spoed") == "ja" && watch("gaas_gebeten") == "ja" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("ja"), t("nee")],
                "helpt_gaas_gebeten",
                t("Helpt het gaasje?")
              )}
            </Grid>
          )}

          {watch("spoed") == "ja" && watch("klacht") == "nabloeding" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("ja"), t("nee")],
                "bloed_verdunners",
                t("Gebruikt u bloedverdunners?")
              )}
            </Grid>
          )}

          {watch("bloed_verdunners") === "ja" && (
            <Grid item xs={12}>
              <FormControl
                fullWidth
                component="fieldset"
                error={!!errors.klacht_uitleg}
              >
                <FormLabel sx={{ mb: 1 }} component="legend">
                  {t("Welke bloedverdunners?")}
                </FormLabel>

                <TextField
                  placeholder={t("Licht toe") + "..."}
                  InputProps={register("welke_bloedverdunners", {
                    required: true,
                  })}
                  multiline
                  minRows={1}
                  maxRows={1}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl
              fullWidth
              component="fieldset"
              error={!!errors.wie_tandarts}
            >
              <FormLabel sx={{ mb: 1 }} component="legend">
                {t("Wie is uw tandarts? (optioneel)")}
              </FormLabel>

              <TextField
                placeholder={t("Licht toe") + "..."}
                InputProps={register("wie_tandarts", { required: false })}
                multiline
                minRows={1}
                maxRows={1}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ mt: 5, display: "flex", justifyContent: "flex-end" }}>
          {!is_spoed_praktijk || (watch("spoed") !== "ja" && <NextButton />)}
          {is_spoed_praktijk && watch("spoed") == "ja" && (
            <Pagination
              loading={loading}
              submit={true}
              submitBackButton={false}
            />
          )}
        </Box>
      </form>
    </React.Fragment>
  );
};

function mapStateToProps(state: any) {
  console.log(typeof state);
  return { formData: state.formData };
}

export default connect(mapStateToProps)(WieTandarts);
